import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/home/home3.jpg";
import Tilt from "react-parallax-tilt";
import ListGroup from 'react-bootstrap/ListGroup';

import { CgPhotoscan } from "react-icons/cg";

import { FaPhotoVideo } from "react-icons/fa";
import { FaGraD } from "react-icons/fa";


function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={7} className="home-about-description">

            <div style={{ textAlign: "left" }}>
              <h2 className="rowdies-bold" style={{ color: "grey" }}>My Service</h2><br/>
              <h4 style={{ color: "grey" }}><FaPhotoVideo/>   Video & Photo</h4>

              <ul className="my-service-list">
                <li>Cinematographer</li>
                <li>Videographer</li>
                <li>Photograper</li>
                <li>Drone</li>
                <li>Editor</li>
                <li>Livestream</li>
              </ul>

            </div>

          </Col>
          <Col md={4} className="myAvtar">
            {/* <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt> */}
            <img src={myImg} className="img-fluid" alt="avatar" />

          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default Home2;
